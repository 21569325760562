import { createUrl } from "src/components/filmweblink/FilmwebLink";
import { WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import { ISearchDocument } from "src/lib/types/search";
import { isFuture, parseISO } from "date-fns";


export function getContentUrl(data: WatchableContentType, forceStreaming?: boolean): string {
    //const streamingId = data.id || data.streamingContentId;
    const streamingId = data.streamingContentId;
    return createUrl((!forceStreaming && data.isCinemaRelevant) || !streamingId, data.movieId, streamingId, data.isSeries);
}

export function getContentUrlAzSearch(data: ISearchDocument, forceStreaming?: boolean): string {
    const streamingId = data.streamingId;

    let isCinemaRelevant = false;

    if (!forceStreaming) {
        if ((data.numShows ?? 0) > 0) {
            isCinemaRelevant = true;
        } else if (data.isCinema && !data.isStreaming) {
            isCinemaRelevant = true;
        } else if (data.movieCinemaPremiere) {
            const parsedPeemiere = parseISO(data.movieCinemaPremiere);
            if (isFuture(parsedPeemiere)) {
                isCinemaRelevant = true;
            }
        }
    }
    return createUrl(isCinemaRelevant, data.edi, streamingId, !data.isMovie)
}